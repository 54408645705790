<template>
  <div class="NameAndCategory mb-20 relative">
    <div class="flex justify-between mb-20">
      <div class="type-base-medium uppercase shadow-bottomLine">
        <nuxt-link
          :to="$manualUrl(globalContent.getBrandLink(productItem.brand.code))"
        >
          {{ productItem.brand.name }}
        </nuxt-link>
      </div>

      <p v-if="showPartNo" class="text-medium-sm type-xs text-darker">
        {{ $t('productPage.partNo.short') }} {{ activeVariant.partNo }}
      </p>
    </div>

    <h1 class="type-headline-xl mb-12" v-text="productItem.extendedLayout ? productItem.name : activeVariant.name" />
    <div
      v-if="showDescription && description"
      class="mobOnly:hidden type-sm"
      v-html="description"
    />
    <div
      v-if="productItem.brand.code == 93"
      id="flix-minisite"
      class="mt-12"
    />
  </div>
</template>

<script setup lang="ts">
import { ProductModel } from '~/models/product';
import ProductVariant from '~/models/productVariant';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContent = useGlobalContentStore();

interface Props {
  productItem: ProductModel,
  activeVariant: ProductVariant,
  showPartNo?: boolean;
  showDescription?: boolean;
  mobile?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  showPartNo: true,
  showDescription: true,
  mobile: false,
});

// Makes sure brands are loaded
onMounted(()=> {
  globalContent.loadBrands();
});

const description = computed(()=> {
  if (props.productItem.extendedLayout && props.productItem.description) {
    return props.productItem.description;
  }
  if (!props.productItem.extendedLayout && props.productItem.shortDescription) {
    return props.productItem.shortDescription;
  }
  return null;
});

</script>

<style scoped lang="postcss">
</style>
